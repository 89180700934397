import { roles } from '@/enums'

export default defineNuxtRouteMiddleware((to, _from) => {
  const user = useSupabaseUser()
  // const schoolId = to.params.schoolId
  // @todo check for right school id
    // Only allow admins to go to admin pages when they enter the right code.
  const runtimeConfig = useRuntimeConfig()
  const { dashboardSecurityCode } = runtimeConfig.public
  if (!_from || _from.query?.key !== dashboardSecurityCode) {
    return navigateTo('/')
  }
  const isTeacher = user.value?.user_metadata.schoolRoles?.find(
    (schoolRole: SchoolRole) => schoolRole.role === roles.TEACHER,
  )
  if (!isTeacher) {
    return navigateTo('/')
  }
})
